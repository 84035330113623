export abstract class UIConstants {
	public static INPUT_DEBOUNCE = 300; // ms
	public static TOAST_TIMEOUT = 5000; // ms
}

export const COUNTRIES = [
	{
		name: 'South Africa',
		isoCode: 'za',
		dialingCode: '27',
		phoneRegex: /^((27|0)|\+27)[6-8]\d{8}$/,
		prefixReplaceRegex: /^((27|0)|\+27)/,
	},
];
